<template>
  <div class="videoOpen">
    <div class="nav-bar">
      视频详情
      <!-- <div class="icon go_leftIcon"></div> -->
      <!-- <div class="nav-bar__title" @click="jumpBack">视频详情</div> -->
    </div>
    <div class="video" id="J_video"></div>
    <div class="pendingConfirm" @click="jumpBack">返回视频列表</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isHaveVideo: false, //有无视频显示
      playerId: "aliplayer_" + Math.random().toString(36).substr(2),
      scriptTagStatus: 0,
      isReload: false,
      instance: null,
    };
  },
  methods: {
    jumpBack() {
      window.history.go(-1);
    },
  },
  mounted() {
    var player = new Aliplayer(
      {
        id: "J_video",
        width: "100%",
        autoplay: false,
        //支持播放地址播放,此播放优先级最高
        source: this.$route.params.data.url,
        cover: `${this.$route.params.data.url}?x-oss-process=video/snapshot,t_50000,f_jpg,w_800,h_600`,
      },
      function (player) {}
    );
  },
};
</script>

<style lang="less">
.videoOpen {
  text-align: left;

  .nav-bar {
    width: 750px;
    height: 79px;
    background: #FFFFFF;
    text-align: center;
    line-height: 79px;
    font-size: 32px;
    // .go_leftIcon {
    //   width: 17px;
    //   height: 30px;
    //   background: url("../assets/images/pending/go_left.png") no-repeat;
    //   background-size: 100%;
    // }

    // .nav-bar__title {
    //   font-size: 32px;
    //   font-family: PingFang SC;
    //   font-weight: 500;
    //   color: #111111;
    //   text-align: center;
    //   line-height: 79px;
    //   cursor: pointer;
    // }
  }
  .pendingConfirm {
    width: 690px;
    height: 80px;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #fafafa;
    text-align: center;
    line-height: 80px;
    background: #ff7000;
    border-radius: 8px;
    margin: 60px auto;
    cursor: pointer;
  }
}
.prism-player .prism-big-play-btn {
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}
</style>